/* Large Devices Styles Starts */

@media (min-width: 1200px) {
	
/* Heading Styles Starts */

	.side-heading1.top {
		margin-top: 0;
	}	
	
/* Navigation Styles Starts */

	#nav .dropdown:hover .dropdown-menu {
		display: block;
	}	
	
/* Welcome Section Styles Starts */

	.welcome-area p:last-of-type {
		margin-bottom: 0;
	}
			
/* List Styles Starts */

	.progress-bar-list h6 {
		line-height: 35px;
		text-align: right;
	}
	.progress-bar-list li .progress .progress-bar {
		padding-right: 20px;
		text-align: right;
	}
		.progress-bar-list > li.row > .col-md-8 {
			border-left: 1px solid #e0e0e0;
		}
		.progress-bar-list > li.row > .col-md-4, 
		.progress-bar-list > li.row > .col-md-8 {
			padding-top: 15px;
			padding-bottom: 15px;
		}
		.progress-bar-list > li:first-of-type.row > .col-md-4, 
		.progress-bar-list > li:first-of-type.row > .col-md-8 {
			padding-top: 10px;
		}
		.progress-bar-list > li:last-of-type.row > .col-md-4, 
		.progress-bar-list > li:last-of-type.row > .col-md-8 {
			padding-bottom: 10px;
		}	
		
/* Contact Info Section Styles Starts */

	.contact-info-box {
		margin-top: 160px;
	}
		.contact-info-box .box-img {
			position: absolute;
			right: 40px;
			bottom: -369px;
		}
	
}

/* Medium Devices Styles Starts */

@media (min-width: 992px) and (max-width: 1199px) {
	
/* Heading Styles Starts */

	.side-heading1.top {
		margin-top: 0;
	}	
	
/* Navigation Styles Starts */

	#nav .dropdown:hover .dropdown-menu {
		display: block;
	}	
	
/* Welcome Section Styles Starts */

	.welcome-area p:last-of-type {
		margin-bottom: 0;
	}
	
/* Doctors Pages Styles Starts */

	ul#doctors-grid.grid > li > .bio-box {
		min-height: 466px;
		height: auto !important;
		height: 466px;
	}
		.featured-doctors .bio-box .profile-img .overlay ul.sm-links {
			margin-top: 30%;
		}
			.featured-doctors .bio-box .profile-img .overlay ul.sm-links li {
				width: 40px;
				height: 40px;
				line-height: 48px;
			
			}
			.featured-doctors .bio-box .profile-img .overlay ul.sm-links li + li {
				margin-left: 15px;
			}
		.doctors-grid .bio-box .profile-img .overlay ul.sm-links {
			margin-top: 25%;
		}
	
/* Doctors Profile Block Styles Starts */

	.panel-profile > .panel-footer .btn {
		margin-top: 15px;
	}
		
/* Accordions Styles Starts */
			
	#accordion .panel-title, 
	#accordion .panel-title .fa {
		font-size: 16px;
	}	
	
/* Book Appointment Box Styles Starts */

	.book-appointment-box .box-img {
		right: 5px;
		bottom: -119px;
	}
	.book-appointment-box h3 {
		font-size: 32px;
	}
	.book-appointment-box h4 {
		font-size: 20px;
	}
	.book-appointment-box .btn-main {
		margin-top: 25px;
		padding: 8px 16px;
	}
	
/* Tabs Styles Starts */	

	.tabs-wrap .nav-tabs > li > a {
		padding: 0 10px 10px;
	}
		.tabs-wrap .nav li .icon {
			margin: 0 auto;				
			width: 110px;
			height: 110px;
			line-height: 110px;
			background-color: #009bdb;
		}
	.tabs-wrap-2 .nav-tabs > li > a {
		padding: 10px 18px;
		font-size: 16px;
	}
			
/* List Styles Starts */

	.progress-bar-list h6 {
		line-height: 35px;		
		text-align: right;
	}
	.progress-bar-list li .progress .progress-bar {
		padding-right: 20px;
		text-align: right;
	}
		.progress-bar-list > li.row > .col-md-8 {
			border-left: 1px solid #e0e0e0;
		}	
		.progress-bar-list > li.row > .col-md-4, 
		.progress-bar-list > li.row > .col-md-8 {
			padding-top: 15px;
			padding-bottom: 15px;
		}
		.progress-bar-list > li:first-of-type.row > .col-md-4, 
		.progress-bar-list > li:first-of-type.row > .col-md-8 {
			padding-top: 10px;
		}
		.progress-bar-list > li:last-of-type.row > .col-md-4, 
		.progress-bar-list > li:last-of-type.row > .col-md-8 {
			padding-bottom: 10px;
		}
		
/* Contact Info Section Styles Starts */

	.contact-info-box {
		margin-top: 100px;
	}
		.contact-info-box .box-img {
			position: absolute;
			right: 0;
			bottom: -436px;
		}	
	
/* Genric Styles Starts */	
	
	.text-center-md {
		text-align: center;
	}
	.img-center-md {
		margin-left: auto;
		margin-right: auto;
	}			
}

/* Small Devices Styles Starts */

@media (min-width: 768px) and (max-width: 991px) {
	
/* Heading Styles Starts */

	.side-heading1.top {
		margin-top: 0;
	}		
	
/* Top Bar Styles Starts */

	.top-bar li {
		padding-right: 0;
		border: none;
	}	
	
/* Navigation Styles Starts */
		
	#nav .navbar-header {
		margin-top: 25px;
		padding-bottom: 20px;
		border-bottom: 1px solid #d3d3d3;
	}
	#nav .navbar-header, 
	#nav .navbar-brand, 
	#nav .navbar-nav, 
	#nav .navbar-right {
		float: none !important;
	}
	#nav .navbar-header, 
	#nav .nav {
		text-align: center;		
	}
	#nav .nav {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	#nav .dropdown:hover .dropdown-menu {
		display: block;
	}
	
/* Welcome Section Styles Starts */

	.welcome-area.about {
		background: none;
	}
	
/* Doctors Bio Boxes Styles Starts */

	ul#doctors-grid.grid > li > .bio-box {
		min-height: 498px;
		height: auto !important;
		height: 498px;
	}
		.featured-doctors .bio-box .profile-img .overlay ul.sm-links {
			margin-top: 26%;
		}
		.doctors-grid .bio-box .profile-img .overlay ul.sm-links {
			margin-top: 25%;
		}
	
/* Doctors Profile Block Styles Starts */

	.panel-profile > .panel-footer .btn {
		margin-top: 15px;
	}	
	
/* Book Appointment Box Styles Starts */	

	.book-appointment-box {
		margin-top: 30px;
	}
		.book-appointment-box .btn-main {
			margin-top: 20px;
		}
		
/* Tabs Styles Starts */	

	.tabs-wrap ul.nav-tabs {
		padding-bottom: 10px;
		border-bottom: 1px solid #cecece;
	}
	.tabs-wrap .nav-tabs > li > a {
		padding: 0 7px;
	}
	.tabs-wrap .nav li h5 {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 16px;
	}
	.tabs-wrap .tab-content { 
		padding-left: 5px;
		padding-right: 5px;
	}
	.tabs-wrap-2 .nav-tabs > li > a {
		padding: 10px 5px;
		font-size: 14px;
	}
	
/* News Page Styles Starts */

	.news-post {
		margin-right: 0;
	}
	
/* Blog Author Bio Box Styles Starts */

	.blog-author-bio {
		margin-right: 0;
	}	
	
/* Comments Area Styles Starts */

	.comments-area .media {
		margin-right: 0;
	}
	
/* Comments Form Styles Starts */

	.comment-form {
		margin-bottom: 50px;
	}	
	
/* Pagination Styles Starts */

	.pagination-wrap {
		margin-right: 0;
	}
	
/* Footer Top Bar Styles Starts */

	.footer-top-bar .pull-left, 
	.footer-top-bar .pull-right {
		float: none !important;
	}
	.footer-top-bar h3 {
		margin-top: 0;
		margin-bottom: 30px;
	}
	
/* Footer Styles Starts */	

	.footer-area .newsletter-block {
		margin-top: 40px;
	}
	
/* Genric Styles Starts */	
	
	.text-center-sm {
		text-align: center;
	}
	.img-center-sm {
		margin-left: auto;
		margin-right: auto;
	}	
	
}

/* Extra Small Devices Styles Starts */

@media (max-width: 767px) {
	
/* Navigation Styles Starts */

	#nav .nav {
		margin-top: 10px;
	}
	#nav .navbar-collapse {
		margin-top: 20px;
	}
	
/* Welcome Section Styles Starts */

	.welcome-area.about {
		background: none;
	}	
	
/* Doctors Bio Boxes Styles Starts */

	ul#doctors-grid.grid > li > .bio-box {
		min-height: 471px;
		height: auto !important;
		height: 471px;
	}
	.featured-doctors .bio-box .profile-img .overlay ul.sm-links {
		margin-top: 13%;
	}
		.featured-doctors .bio-box .profile-img .overlay ul.sm-links li {
			width: 40px;
			height: 40px;
			line-height: 48px;
		
		}
		.featured-doctors .bio-box .profile-img .overlay ul.sm-links li + li {
			margin-left: 15px;
		}	
	.doctors-grid .bio-box .profile-img .overlay ul.sm-links {
		margin-top: 15%;
	}
	
/* Doctors Profile Block Styles Starts */

	.panel-profile {
		margin-bottom: 50px;
	}
		.panel-profile > .panel-footer .btn {
			margin-top: 15px;
		}	
		
/* Latest News Carousel Starts */

	.news-carousel .news-post {
		margin-top: 20px;
	}
	
/* Book Appointment Box Styles Starts */

	.book-appointment-box {
		margin-top: 30px;
	}	
	
/* Tabs Styles Starts */	

	.tabs-wrap ul.nav-tabs {
		padding-bottom: 10px;
		border-bottom: 1px solid #cecece;
	}
	.tabs-wrap .nav-tabs > li > a {
		padding: 0 10px 10px;
	}
	.tabs-wrap .nav li h5 {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 16px;
	}
	.tabs-wrap .tab-content { 
		padding-left: 5px;
		padding-right: 5px;
	}
	.tabs-wrap-2 .nav-tabs > li, 
	.tabs-wrap-2 .nav-tabs > li:last-of-type {
		width: 100%;
	}
	.tabs-wrap-2 .nav-tabs > li, 
	.tabs-wrap-2 .nav-tabs > li > a {
		display: block;
		float: none !important;
	}
	.tabs-wrap-2 .nav-tabs > li > a {
		border: 1px solid #e2e2e2;
	}
	.tabs-wrap-2 .nav-tabs > li > a:after {
		display: none;
	}
	
/* News Page Styles Starts */

	.news-post {
		margin-right: 0;
	}
	
/* Blog Author Bio Box Styles Starts */

	.blog-author-bio {
		margin-right: 0;
	}	
	
/* Comments Area Styles Starts */

	.comments-area .media {
		margin-right: 0;
	}
	
/* Pagination Styles Starts */

	.pagination-wrap {
		margin-right: 0;
	}
	
/* Contact Form Styles Starts */

	#main-contact-form {
		margin-bottom: 50px;
	}	
	
/* Footer Top Bar Styles Starts */

	.footer-top-bar .pull-left, 
	.footer-top-bar .pull-right {
		float: none !important;
	}
	.footer-top-bar h3 {
		margin-top: 0;
		margin-bottom: 30px;
	}	
	
/* Footer Styles Starts */	

	.footer-area .col-xs-12 h4 {
		margin-top: 30px;
		margin-bottom: 20px;
	}	
	.footer-area .col-xs-12:first-of-type h4 {
		margin-top: 0;
	}	
	.footer-area .tweets-list {	
		margin-left: 15px;
	}
	
/* Copyright Styles Starts */

	.copyright {
		text-align: center;
	}
		.copyright .pull-left, 
		.copyright .pull-right {
			float: none !important;
		}
		.copyright ul {
			margin-top: 15px;
		}
		
/* Genric Styles Starts */	
	
	.text-center-xs {
		text-align: center;
	}
	.img-center-xs {
		margin-left: auto;
		margin-right: auto;
	}			
	
}

/* Extremely Small Devices Styles Starts */

@media (max-width: 478px) {
	
/* Navigation Styles Starts */

	#nav .navbar-brand {
		padding-top: 35px;
		font-size: 24px;
	}
		#nav .navbar-brand .fa {
			margin-right: 3px;
			font-size: 30px;
		}
	#nav .navbar-collapse {
		margin-top: 20px;
	}
	
/* Main Banner Styles Starts */

	.main-banner h2 {
		display: none;
	}
	
/* About Featured Section Starts */

	.about-featured .btn-transparent {
		margin-top: 20px;
		padding: 10px 20px;
		font-size: 16px;
	}
		.about-featured .btn-transparent .fa {
			margin-left: 10px;
		}
	
/* Doctors Bio Boxes Styles Starts */

	ul#doctors-grid.grid > li > .bio-box {
		min-height: 465px;
		height: auto !important;
		height: 465px;
	}
		.featured-doctors .bio-box .profile-img .overlay ul.sm-links {
			margin-top: 30%;
		}		
		.doctors-grid .bio-box .profile-img .overlay ul.sm-links {
			margin-top: 24%;
		}
	
/* Latest News Carousel Starts */

	.news-carousel .news-post {
		margin-top: 40px;
	}
	.news-carousel .carousel-control {
		top: -7px;
	}
	.news-carousel .carousel-control.left {
		left: 0;
		right: auto;
	}
	
/* Accordions Styles Starts */

	#accordion .panel-heading {
		padding: 10px 15px;
	}
	#accordion .panel-title a, 
	#accordion .panel-title span.fa.pull-right {
		padding: 0;
	}
	#accordion .panel-title, 
	#accordion .panel-title .fa {
		font-size: 16px;
	}
	#accordion .panel-title .icon {
		display: none;
	}
	#accordion .panel-body {
		padding: 15px;
	}
	
/* Book Appointment Box Styles Starts */

	.book-appointment-box {
		margin-top: 20px;
		padding: 20px 15px;
	}
	.book-appointment-box h3 {
		margin-top: 15px;		
		font-size: 26px;
	}
	.book-appointment-box h4 {
		font-size: 22px;
	}
	.book-appointment-box .btn-main {
		margin-top: 15px;
		padding: 8px 14px;
		font-size: 15px;
	}	
	
/* Tabs Styles Starts */	

	.tabs-wrap .nav-tabs > li, 
	.tabs-wrap .nav-tabs > li a {
		display: block !important;
	}	
	
/* Contact Info Section Styles Starts */

	.contact-info-box .info-box {
		padding: 50px 30px 30px;
	}
	.contact-info-box .info-box h3 {
		font-size: 32px;
	}
	.contact-info-box .info-box h5 {
		font-size: 16px;
		line-height: 26px;
	}
	.contact-info-box .info-box h4 {
		font-size: 20px;
	}
	
}